<div [hidden]="formLoading" class="service-desk-page">
  <div class="columns is-flex is-centered">
    <div class="column is-two-fifths is-four-fifths-mobile">
      <div class="columns is-marginless pb-1">
        <div class="column">
          <div class="has-text-lfblack font-18px bold has-text-centered">
            CONTACT US
          </div>
        </div>
      </div>
      <div class="cb-title-group">
        <div class="secondary-text">
          Please provide your contact information and a description of the problem you are experiencing.
          <br />
          Your request will be submitted to our support team for review.
        </div>
      </div>

      <form class="form" [formGroup]="form" (ngSubmit)="submit()">
        <div class="field">
          <cb-input iconName="user" autofocus label="Name" formControlName="name" [errors]="nameErrors"></cb-input>
        </div>
        <div class="field">
          <cb-input iconName="envelope" label="Email address" formControlName="email" [errors]="emailErrors"></cb-input>
        </div>
        <div class="field">
          <cb-textarea formControlName="description" label="Description" rows="8" [errors]="descriptionErrors">
          </cb-textarea>
        </div>
        <div class="service-desk-alert">
          <cb-alert *ngIf="requestNotSent" type="error">
            Message request not sent
          </cb-alert>
        </div>
        <div class="actions">
          <cb-button buttonType="submit" type="is-info px-3" [disabled]="!form.valid || !captchaToken">
            Send
          </cb-button>
          <a class="cb-link gray cancel" *ngIf="showbackLink" cbNavigateTo="/cb/login">Back</a>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
